<template>
  <div class="billingTable">

    <div v-if="selectedTableView === 'detail'">
      <b-col sm="5" md="6" class="my-1" v-if="companyTransactions.count > perPage">
        <b-form-group
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
        >
        </b-form-group>
      </b-col>

      <b-col sm="12" md="12" class="my-1" v-if="companyTransactions.count > perPage">
        <b-pagination
            v-model="currentPage"
            :total-rows="companyTransactions.count"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
        ></b-pagination>
      </b-col>
    </div>

    <b-table
        v-if="this.role.includes('operator')"
        head-variant="light"
        :current-page="currentPage"
        :empty-text="$t('billing.empty_text')"
        :per-page="perPage"
        :busy="isLoading"
        :items="companyTransactions.data"
        :fields="fields"
        responsive
        show-empty
        small
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>

      <template #cell(created_at)="row">
        {{row.value | moment('DD.MM.YYYY, HH:mm')}}
      </template>

      <template #cell(subject)="row">
        {{row.value}}
      </template>

      <template #cell(reason)="row">
        {{$t('billing.'+row.value) }}
      </template>

      <template #cell(amount)="row">
<!--        {{row.value}}-->
        <div v-if="row.item.event ==='increase'">+{{row.value}}</div><div v-else>-{{row.value}}</div>
      </template>

      <template #cell(balance)="row">
        {{row.value}}
      </template>

      <template #cell(details)="row">
        <div v-if="typeof row.value !=='undefined' && row.value && row.value.results">
          <div class="d-none d-lg-block">
            <i class="feather icon-info" data-bs-toggle="dropdown" style="cursor: pointer"></i>
            <div class="dropdown-menu dropdownContent" style="max-height: 15rem; overflow: hidden; overflow-y: auto; margin: 1rem" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
              <div v-for="result in row.value.results" v-if="result.services_results.length || result.sources_results.length">
                <div class="title" style="padding-left: 10px">{{$t('billing.iteration') }}:{{result.iteration}}<span v-if="result.param">{{'('+$t('params.'+result.param)+')'}}</span></div>
                <ul>
                  <li style="padding-left: 1rem; padding-right: 1rem; display: flex;justify-content: space-between" v-for="services_results in result.services_results">
                    <div>{{services_results.source}}:</div>
                    <div>{{services_results.cost}}</div>
                  </li>
                  <li style="padding-left: 1rem; padding-right: 1rem; display: flex;justify-content: space-between" v-for="sources_results in result.sources_results">
                    <div>{{sources_results.source}}:</div>
                    <div>{{sources_results.cost}}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="d-flex d-xl-none">
            <i class="feather icon-info" v-b-modal="`cost_${row.index}`" data-bs-toggle="modal" style="cursor: pointer"></i>
            <b-modal  :id="`cost_${row.index}`" :title="$t('billing.details')" centered hide-footer>
              <div v-for="result in row.value.results" v-if="result.services_results.length || result.sources_results.length">
                <div class="title" style="padding-left: 10px">{{$t('billing.iteration') }}:{{result.iteration}}<span v-if="result.param">{{'('+$t('params.'+result.param)+')'}}</span></div>
                <ul>
                  <li style="padding-left: 1rem; padding-right: 1rem; display: flex;justify-content: space-between" v-for="services_results in result.services_results">
                    <div>{{services_results.source}}:</div>
                    <div>{{services_results.cost}}</div>
                  </li>
                  <li style="padding-left: 1rem; padding-right: 1rem; display: flex;justify-content: space-between" v-for="sources_results in result.sources_results">
                    <div>{{sources_results.source}}:</div>
                    <div>{{sources_results.cost}}</div>
                  </li>
                </ul>
              </div>
              <footer class="d-grid gap-2">
              </footer>
            </b-modal>
          </div>
        </div>
      </template>
    </b-table>

    <b-table
        v-if="!this.role.includes('operator') && selectedTableView === 'detail'"
        :current-page="currentPage"
        :empty-text="$t('billing.empty_text')"
        :per-page="perPage"
        head-variant="light"
        :items="companyTransactions.data"
        :fields="fields"
        :busy="isLoading"
        responsive
        show-empty
        small
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>


      <template #cell(created_at)="row">
        {{row.value | moment('DD.MM.YYYY, HH:mm')}}
      </template>

      <template #cell(subject)="row">
        {{row.value}}
      </template>

      <template #cell(reason)="row">
        {{$t('billing.'+row.value) }}
      </template>

      <template #cell(amount)="row">
        <div v-if="row.item.event ==='increase'">+{{row.value}}</div><div v-else>-{{row.value}}</div>
      </template>

      <template #cell(balance)="row">
        {{row.value}}
      </template>


      <template #cell(details)="row">

        <div v-if="typeof row.value.login !== 'undefined'">
          <div class="increase" v-if="row.item.event === 'increase'">{{$t('billing.gave_out') }} {{row.value.login}}</div>
          <div class="increase" v-if="row.item.event === 'decrease'">{{$t('billing.to') }} {{row.value.login}}</div>
        </div>
        <div v-if="typeof row.value !=='undefined' && row.value && row.value.results">
          <div class="d-none d-lg-block">
            <i class="feather icon-info" data-bs-toggle="dropdown" style="cursor: pointer"></i>
            <div class="dropdown-menu dropdownContent" style="max-height: 15rem; overflow: hidden; overflow-y: auto; margin: 1rem" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
              <div v-for="result in row.value.results" v-if="result.services_results.length || result.sources_results.length">
                <div class="title" style="padding-left: 10px">{{$t('billing.iteration') }}:{{result.iteration}}<span v-if="result.param">{{'('+$t('params.'+result.param)+')'}}</span> </div>
                <ul>
                  <li style="padding-left: 1rem; padding-right: 1rem; display: flex;justify-content: space-between" v-for="services_results in result.services_results">
                    <div>{{services_results.source}}:</div>
                    <div>{{services_results.cost}}</div>
                  </li>
                  <li style="padding-left: 1rem; padding-right: 1rem; display: flex;justify-content: space-between" v-for="sources_results in result.sources_results">
                    <div>{{sources_results.source}}:</div>
                    <div>{{sources_results.cost}}</div>
                  </li>
                </ul>
              </div>
              <div v-if="typeof row.value.discount !== 'undefined' && row.value.discount > 0" style="padding-left: 8px; font-weight: 700; padding-bottom: 4px">{{$t('billing.discount') }}: {{row.value.discount}}</div>
            </div>
          </div>

          <div class="d-flex d-xl-none">
            <i class="feather icon-info" v-b-modal="`cost_${row.index}`" data-bs-toggle="modal" style="cursor: pointer"></i>
            <b-modal  :id="`cost_${row.index}`" :title="$t('billing.details')" centered hide-footer>
              <div v-for="result in row.value.results" v-if="result.services_results.length || result.sources_results.length">
                <div class="title" style="padding-left: 10px">{{$t('billing.iteration') }}:{{result.iteration}} <span v-if="result.param">{{'('+$t('params.'+result.param)+')'}}</span> </div>
                <ul>
                  <li style="padding-left: 1rem; padding-right: 1rem; display: flex;justify-content: space-between" v-for="services_results in result.services_results">
                    <div>{{services_results.source}}:</div>
                    <div>{{services_results.cost}}</div>
                  </li>
                  <li style="padding-left: 1rem; padding-right: 1rem; display: flex;justify-content: space-between" v-for="sources_results in result.sources_results">
                    <div>{{sources_results.source}}:</div>
                    <div>{{sources_results.cost}}</div>
                  </li>
                </ul>
              </div>
              <div v-if="typeof row.value.discount !== 'undefined' && row.value.discount > 0" style="padding-left: 8px;padding-bottom: 4px; font-weight: 700">{{$t('billing.discount') }}: {{row.value.discount}}</div>
              <footer class="d-grid gap-2">
              </footer>
            </b-modal>
          </div>
        </div>
      </template>
    </b-table>

    <b-table
        v-if="selectedTableView === 'daily'"
        head-variant="light"
        :empty-text="$t('billing.empty_text')"
        :items="dailyCompanyTransactions"
        :fields="fields"
        :busy="isLoading"
        responsive
        show-empty
        small
    >

      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>


      <template #cell(date)="row">
        {{row.value | moment('DD.MM.YYYY')}}
      </template>

      <template #cell(subject)="row">
        {{row.value}}
      </template>

      <template #cell(reason)="row">
        {{$t('billing.'+row.value) }}
      </template>

      <template #cell(balance)="row">
        {{row.value}}
      </template>

    </b-table>

    <b-table
        v-if="selectedTableView === 'summary'"
        head-variant="light"
        :empty-text="$t('billing.empty_text')"
        :items="summaryCompanyTransactions"
        :fields="fields"
        :busy="isLoading"
        responsive
        show-empty
        small
    >


      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>

      <template #cell(date)="row">
        {{row.value | moment('DD.MM.YYYY')}}
      </template>

      <template #cell(directOutcome)="row">
        <div style="display:flex;"><div v-if="row.value !== 0">- </div><div>{{ row.value }}</div> </div>
      </template>

      <template #cell(balanceIncome)="row">
        <div style="display:flex;"><div v-if="row.value !== 0">+ </div><div>{{ row.value }}</div> </div>
      </template>

    </b-table>

  </div>
</template>

<script>


export default {
  name: "companyView",
  props:["role", "companyTransactions","selectedTableView", 'isLoading'],
  data() {
    return {
      headVariant: "dark",
      bordered: true,
      currentPage: 1,
      perPage: 50,
    }
  },
  computed:{
      fields(){
        if (this.selectedTableView === 'detail'){
          if (this.role.includes('operator')){
            return [
              {
                key: 'created_at',
                label: this.$t('billing.date_time'),
                sortable: true
              },
              {
                key: 'subject',
                label: this.$t('billing.login'),
                sortable: true
              },
              {
                key: "reason",
                label: this.$t('billing.event'),
                sortable: true
              },
              {
                key: "amount",
                label: this.$t('billing.sum'),
                sortable: true
              },
              {
                key: "balance",
                label: this.$t('billing.balanceRest'),
                sortable: true
              },
              {
                key: "details",
                label: this.$t('billing.details'),
                sortable: false
              }
            ]
          } else {
            return [
              {
                key: 'created_at',
                label: this.$t('billing.date_time'),
                sortable: true
              },
              {
                key: 'subject',
                label: this.$t('billing.login'),
                sortable: true
              },
              {
                key: "reason",
                label: this.$t('billing.event'),
                sortable: true
              },
              {
                key: "amount",
                label: this.$t('billing.sum'),
                sortable: true
              },
              {
                key: "balance",
                label: this.$t('billing.balanceRest'),
                sortable: true
              },
              {
                key: "details",
                label: this.$t('billing.details'),
                sortable: false
              }
            ]
          }
        } else if (this.selectedTableView === 'daily'){
          return [
            {
              key: 'date',
              label: this.$t('billing.date'),
              sortable: true
            },

            {
              key: 'subject',
              label: this.$t('billing.login'),
              sortable: true
            },
            {
              key: 'decreaseCount',
              label: this.$t('billing.spentOnSearch'),
              sortable: true
            },
            {
              key: "balance",
              label: this.$t('billing.balanceRest'),
              sortable: true
            },
          ]
        } else if (this.selectedTableView === 'summary'){
          return [
            {
              key: 'subject',
              label: this.$t('billing.login'),
              sortable: true
            },
            {
              key: 'searchCount',
              label: this.$t('billing.requestsPerPeriod'),
              sortable: true
            },
            {
              key: 'searchesCost',
              label: this.$t('billing.spentOnSearch'),
              sortable: true
            },
            {
              key: 'directOutcome',
              label: this.$t('billing.TransferOfCoins'),
              sortable: true
            },
            {
              key: 'balanceIncome',
              label: this.$t('billing.balanceIncome'),
              sortable: true
            },
            {
              key: "balance",
               label: this.$t('billing.balance'),
              sortable: true
            },
          ]
        }

      },
      dailyCompanyTransactions(){
        if (!this.isLoading){
          let dailyTransaction = this.companyTransactions
          if (typeof dailyTransaction !=='undefined' && dailyTransaction.length){
            dailyTransaction.forEach(transaction =>{
              let counter = 0;
              if (typeof transaction.decrease !== 'undefined' && typeof transaction.decrease !== "number" ){
                transaction.decrease.forEach(decrease =>{
                  if (decrease.reason === 'SEARCH_OUTCOME'){
                    counter+= decrease.amount
                  }
                })
              }
              transaction.decreaseCount = counter
            })
          }
          return dailyTransaction
        }

      },
      summaryCompanyTransactions(){
        if (!this.isLoading){
          let summaryTransaction = this.companyTransactions;
          if (typeof summaryTransaction !=='undefined'){
            summaryTransaction.forEach(transaction =>{
              let searchCounter = 0;
              let searchesCost = 0;
              let directOutcome = 0;
              let balanceIncome = 0;
              if (typeof transaction.decrease !== 'undefined'){
                transaction.decrease.forEach(decrease =>{
                  if (decrease.reason === 'SEARCH_OUTCOME'){
                    searchCounter+= decrease.count
                    searchesCost += decrease.amount
                  } else if (decrease.reason === "DIRECT_OUTCOME"){
                    directOutcome += decrease.amount
                  }
                })
              }
              if (typeof transaction.increase !== 'undefined'){
                transaction.increase.forEach(increase =>{
                  balanceIncome += increase.amount
                })
              }
              transaction.balanceIncome = balanceIncome
              transaction.directOutcome = directOutcome
              transaction.searchCount = searchCounter
              transaction.searchesCost = searchesCost
            })
          }
          return summaryTransaction
        }

      },

  },
}
</script>

<style scoped lang="scss">
.billingTable .thead-light{
    background-color: #f8f9fa;
}
.thead-light th {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
</style>
<style scoped lang="scss">
.iconContainer{
  display: flex;
  align-items: center;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  cursor: pointer;
  position: relative;
  .itemCost{
    padding-top: 2px;
    padding-left: 8px;
  }
}
.costContainer{
  z-index: 111;
  background-color: #FFFFFF;
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  top: 30px;
  left: 0;
}
.headerDropdown{
  width: max-content;
  display: flex;
}

.source{
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
  align-items: flex-start;
  img{
    margin-top: 4px;
  }
}
.list:last-child{
  margin-bottom: 0.8rem;
}
.paddingSide{
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.zeroCost::after{
  display: none;
}
.hesCost{
  //padding-top: 1.2rem;
}


.title{
  padding-top: 4px;
  padding-bottom: 2px;
  margin-bottom: 8px;
  background-color: rgba(0, 0, 0, 0.10);
}
.total{
  font-size: 14px;
  padding-top: 4px;
  padding-bottom: 2px;
  font-weight: 600;
}
</style>