<template>
  <div>
    <b-table
        head-variant="light"
        :items="summaryClientTransactions"
        :fields="fields"
        :busy="isLoading"
        :empty-text="$t('billing.empty_text')"
        responsive
        show-empty
        small
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>

      <template #cell(date)="row">
        {{row.value }}
      </template>

      <template #cell(subject)="row">
        {{row.value}}
      </template>

      <template #cell(reason)="row">
        {{$t('billing.'+row.value) }}
      </template>

      <template #cell(balance)="row">
        {{row.value}}
      </template>

    </b-table>
  </div>
</template>

<script>
export default {
  name: "clientView",
  props:["role", "clientTransactions",'isLoading'],
  data() {
    return {
      headVariant: "dark",
      bordered: true,
    }
  },
  computed:{
    fields(){
          return [
            {
              key: 'date',
              label: this.$t('billing.date'),
              sortable: true
            },
            {
              key: 'subject',
              label: this.$t('billing.login'),
              sortable: true
            },
            {
              key: 'searchesCost',
              label: this.$t('billing.spentOnSearchAdmins'),
              sortable: true
            },
            {
              key: "balance",
              label: this.$t('billing.balanceRestAdmin'),
              sortable: true
            },

          ]
    },
    summaryClientTransactions(){
      if (typeof this.clientTransactions !=='undefined'){
        this.clientTransactions.forEach(transaction =>{
          if (typeof transaction.decrease !== 'undefined'){
            let searchesCost = 0;
            transaction.decrease.forEach(decrease =>{
              if (decrease.reason === 'SEARCH_OUTCOME'){
                searchesCost += decrease.amount
              }
            })
            transaction.searchesCost = searchesCost
          }
        })
      }
      return this.clientTransactions
    },
  },
}
</script>

<style>
.billingTable .thead-light{
  background-color: #f8f9fa;
}
.thead-light th {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.tables tbody td {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
</style>